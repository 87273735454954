<template>
  <div>
    <div v-if="authenticated" class="h-full">
      <div v-click-outside="closeOpener"
           v-on:click="toggleOpener"
           v-bind:class="{'bg-accent-100 shadow-inner': opener}"
           class="w-full h-full flex cursor-pointer hover:bg-accent-100 hover:text-core-900 px-8 transition duration-200 ease">
        <span class="inline-flex items-center w-11/12">
          <text-button v-bind:label="username"
                       size="lg"
                       icon="user"
                       width="2rem"
                       height="2rem"
                       label_margin="5"
                       v-bind:dark="true"
                       class="flex-1"
                       v-bind:class="{'active': opener}"/>
          <em class="flex-1 flex font-normal whitespace-no-wrap ml-1 mr-4">
            {{user.entite && `(${user.entite})`}}
          </em>
          <span class="flex flex-1 items-center justify-center text-white text-xs py-1 px-2 mr-3 rounded-sm shadow-lg whitespace-no-wrap bg-core-600">
            <span class="ml-1">
              {{user.is_gestionnaire || user.email === 'maggie.tare@test.cncc.fr'? 'Gestionnaire Client' : 'Participant'}}
            </span>
          </span>

        </span>
        <span class="inline-flex w-1/12 pointer-events-none inset-y-0 right-0 items-center justify-end">
          <svg class="fill-current h-4 w-4"
               xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 20 20">
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
        </span>
      </div>

      <transition name="slide-up">
        <div v-if="opener" class="mt-1 rounded-lg shadow text-core-600 bg-core-100 mr-3 z-20 font-normal overflow-hidden">
          <text-button v-bind:href="{name:'utilisateur'}"
                       v-on:click="opener = false"
                       v-bind:label="'Paramètres de compte'"
                       size="lg"
                       icon="cog"
                       width="2rem"
                       height="2rem"
                       v-bind:dark="true"
                       label_margin="5"
                       class="hover:bg-accent-200 hover:text-core-900 active:shadow-inner py-5 px-3 transition duration-150 ease"/>

          <logout-button classes="hover:bg-accent-200 hover:text-core-900 active:shadow-inner py-5 px-3 transition duration-150 ease"/>

        </div>
      </transition>

    </div>

    <div v-else
         class="w-56 h-full"
         v-click-outside="closeOpener">
      <div v-on:click="toggleOpener"
           v-bind:class="{'bg-accent-200 shadow-inner': opener}"
           class="w-full h-full flex cursor-pointer hover:bg-accent-200 hover:text-core-900 px-8 transition duration-200 ease">
        <span class="flex-1 w-11/12">
          <text-button label="Se connecter"
                       size="lg"
                       icon="user"
                       width="2rem"
                       height="2rem"
                       v-bind:dark="true"
                       label_margin="5"
                       v-bind:class="{'active': opener , 'alternative': scrolled}"/>
        </span>
        <span class="w-1/12 pointer-events-none inset-y-0 right-0 flex items-center justify-end">
          <svg class="fill-current h-4 w-4"
               xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 20 20">
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
        </span>
      </div>
      <transition name="slide-up">
        <div v-if="opener"
             class="absolute right-0 mr-3 mt-1 rounded-lg text-center shadow text-core-600 bg-core-100 z-20 py-2 font-normal"
             v-bind:class="{'text-white': scrolled}">
          <login-form v-on:logged-in="closeOpener" is_navbar/>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';

import textButton from '@/components/utils/text-button.vue';
import loginForm from '@/components/utils/login-form.vue';
import logoutButton from '@/components/utils/logout-button.vue';

import {
    createNamespacedHelpers
} from 'vuex';

const {
    mapState: mapStateAuth,
    mapGetters: mapGettersAuth
} = createNamespacedHelpers('auth');

const {
    mapState: mapStateObserver
} = createNamespacedHelpers('observer');

export default {
    components: {
        loginForm,
        logoutButton,
        textButton
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    data() {
        return {
            opener: false
        };
    },
    computed: {
        ...mapStateAuth({
            user: (state) => state.user,
            authenticated: (state) => state.authenticated
        }),
        ...mapGettersAuth([
            'username',
        ]),
        ...mapStateObserver({
            scrolled: (state) => state.scrolled
        })
    },
    methods: {
        closeOpener() {
            this.opener = false;
        },
        toggleOpener() {
            this.opener = !this.opener;
        }
    }
};
</script>

<style lang="scss" scoped>

// https://vue-loader.vuejs.org/guide/scoped-css.html#deep-selectors
// Must use prefix /deep/ for SASS ( instead of prefix >>> ) to make vue-loader properly styling the children component within a scoped state.
/deep/ .text-link {
    @apply justify-start;

    .fill-current,
    .text-link__name {
        @apply flex-1;
        color: inherit;
    }
    &.active.alternative,
    &:hover {
        .text-link__name {
            // @apply text-white;
        }
    }
}
</style>
