<template>
  <div class="select-box">

    <div class="select-box__header">
      <form-label v-bind:label="label"
                  v-bind:icon="label_icon"
                  class="header__label"/>

      <div v-if="selected.length && multiple"
           class="header__btn-clear"
           v-on:click.prevent.stop="updateValue([])">
        <span class="clear">Effacer tout</span>
      </div>
    </div>

    <multiselect v-model="selected"
                 v-bind:disabled="disabled"
                 v-bind:multiple="multiple"
                 v-on:input="updateValue"
                 v-bind:options="categories"
                 v-bind:placeholder="placeholder ? placeholder : `Sélectionnez`"
                 v-bind:preserve-search="true"
                 label="label"
                 v-bind:track-by="tracking"
                 v-bind:allow-empty="multiple"
                 select-label=""
                 selected-label=""
                 v-bind:deselect-label="multiple ? 'Retirer': ''"
                 v-bind:close-on-select="!multiple"
                 v-bind:open-direction="open_direction? open_direction: ''"
    >

      <template slot="selection" slot-scope="{ values, isOpen }">
        <span v-if="values.length && !isOpen"
              class="multiselect__single">
          <span v-if="message_key">
            <strong>{{$tc(`selectBox.${message_key}.selectionStrong`, values.length)}}</strong> {{$tc(`selectBox.${message_key}.selection`, values.length)}}
          </span>
          <span v-else>
            <strong>{{values.length}} {{label}}</strong> sélectionné(e){{values.length >= 2 ? 's': null}}
          </span>
        </span>
      </template>

      <template slot="tag">
        <span>&nbsp;</span>
      </template>

      <template v-for="slot_name in ['singleLabel', 'option']"
                v-bind:slot="slot_name"
                slot-scope="{ option }">
        <font-awesome-icon v-bind:key="`icon-${slot_name}`"
                           v-if="option.icon"
                           v-bind:icon="option.icon"
                           class="thematique__icon"/>
        <span v-bind:key="`label-${slot_name}`">
          <template v-if="no_capitalize_label">
            {{option.label}}
          </template>
          <template v-else>
            {{option.label | firstLetterCapitalize}}
          </template>
        </span>
      </template>

      <template slot="noOptions">
        <span class="select-box__no-option" v-if="message_key">
          {{$t(`selectBox.${message_key}.noOptions`)}}
        </span>
        <span class="select-box__no-option" v-else>
          {{`Aucune sélection`}}
        </span>
      </template>
      <template slot="noResult">
        <span class="select-box__no-result" v-if="message_key">
          {{$t(`selectBox.${message_key}.noResults`)}}
        </span>
        <span class="select-box__no-result" v-else>
          {{`Aucun résultat trouvé`}}
        </span>
      </template>
    </multiselect>
  </div>
</template>

<script>
import helpers_filters from '@/mixin/helpers_filters';
import Multiselect from 'vue-multiselect';

import formLabel from '@/components/utils/form-label.vue';

export default {
    components: {
        formLabel,
        Multiselect
    },
    mixins: [helpers_filters],
    props: {
        value: {
            type: [Object, Array, String, Number],
            default: () => {
                const array = [];
                return array;
            }
        },
        open_direction: {
            type: String
        },
        name: {
            type: String
        },
        placeholder: {
            type: String
        },
        multiple: {
            type: Boolean,
            default: false
        },
        tracking: {
            default: 'id'
        },
        is_update_method: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        no_capitalize_label: {
            type: Boolean,
            default: false
        },
        label: {
            type: String
        },
        label_icon: {
            type: [String, Array]
        },
        categories: {
            type: Array,
            default() {
                return [];
            }
        },
        message_key: {
            type: String
        }
    },
    data() {
        return {
            selected: this.value,
            is_focused: false,
        };
    },
    watch: {
        value(newVal) {
            this.selected = newVal;
        }
    },
    methods: {
        updateValue(value) {
            if (this.is_update_method) {
                this.$emit('input', {
                    value,
                    name: this.name
                });
            } else {
                this.selected = value;
                this.$emit('input', this.selected);
            }
        }
    }

};
</script>

<style lang="scss" scoped>
.select-box {
    @apply w-full;

    /deep/ .multiselect {
      .multiselect__option {
        @apply leading-normal;
      }
    }

    .select-box__header {
        @apply flex w-full leading-none;
        .header__label {
            @apply flex-grow inline-flex font-bold mb-2 leading-none;
        }
        .header__btn-clear {
            @apply flex-shrink inline-flex justify-end items-center;
            .clear {
                @apply inline-flex cursor-pointer rounded z-10 w-full px-2 h-4 items-center justify-center font-bold text-white text-xs bg-danger-600 shadow;
                &:hover {
                    @apply bg-danger-500;
                }
            }
        }
    }

    .thematique__icon {
        @apply mr-3;
    }

    .select-box__no-option {
      @apply text-core-500 italic text-sm;
    }

  .select-box__no-result {
    @apply text-core-500 text-sm;
  }
}
</style>
