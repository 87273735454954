<template>
  <img class="logo-image"
       v-bind:class="[extraclass ? `${extraclass}`: '']"
       v-bind:src="src"/>
</template>
<script>
export default {
    props: ['src', 'extraclass']
};
</script>
<style lang="scss" scoped>
  .logo-image {
    @apply h-full mx-auto bg-white p-1;
  }
</style>
