<template>
  <div class="flex justify-center items-center">
    <form method="post"
          class="p-6 flex flex-col">

      <div class="flex items-center justify-between">
        <a v-bind:href="connectWithOidcProvider"
           v-on:click="triggerRedirectPath"
           class="w-full rounded text-xs shadow hover:shadow-inner uppercase bg-core-700 hover:bg-primary-900 text-white font-bold py-5 px-8 focus:outline-none focus:shadow-outline transition duration-100 ease-in-out">
          <span class="ml-3">Se connecter avec SAFIR Connect</span>
        </a>
      </div>

      <div class="flex items-center justify-center mt-4">
        <a href="#"
           class="inline-block normal-case text-sm hover:text-primary-900 hover:underline"
           v-tooltip="is_navbar ? {
             placement: 'bottom',
             offset: '30',
             /* show:true, */
             /* autoHide:false, */
             content: safirInfo()
           } : null">
          <span class="mx-2">Vous n'avez pas de compte SAFIR Connect ?</span>
          <font-awesome-icon v-if="is_navbar"
                             size="lg"
                             v-bind:icon="['fas', 'info-circle']"/>
        </a>
      </div>
    </form>

    <div v-if="!is_navbar"
         v-html="safirInfo()"
         class="flex flex-col text-left ml-2 pl-6 border-core-400 border-l-1"></div>

  </div>
</template>
<script>
// import {AUTH} from '@/http-common';
import {createNamespacedHelpers} from 'vuex';
const {mapState: mapStateAuth, mapMutations: mapMutationsAuth} = createNamespacedHelpers('auth');

export default {
    props: {
        is_navbar: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapStateAuth({
            authenticated: (state) => state.authenticated,
            redirect_route_path: (state) => state.redirect_route_path,
        }),
        connectWithOidcProvider() {
            return `${process.env.VUE_APP_API_AUTH}/login?to=https://${process.env.VUE_APP_HOST_CATALOGUE}`;
        },
    },
    methods: {
        ...mapMutationsAuth({
            setRedirectUrlPath: 'setRedirectUrlPath'
        }),
        triggerRedirectPath() {
            this.connectWithOidcProvider();
            if (!this.$route.meta.requiresAuth && this.$route.name !== 'connexion') {
                this.setRedirectUrlPath(this.$route.fullPath);
            }
        },
        safirInfo() {
            return `<section class='w-84 py-4 px-2 mb-0'>
          <h6 class='m-0 mb-4'>SAFIR Connect qu'est-ce que c'est ?</h6>
          <p class='m-0 text-sm text-justify'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias reprehenderit aliquid, tempore. Ipsam dignissimos, magni deleniti, quia, architecto maiores odio numquam delectus officia aspernatur quo totam harum incidunt ea necessitatibus enim ratione. Incidunt explicabo optio illo consequuntur earum perferendis quis excepturi sint, nam unde quaerat iste sequi rerum, quas? Ducimus.</p>
          </section>`;
        },
    }
};
</script>

<style lang="scss" scoped>
</style>
