<template>
  <div v-if="listSessions(sessions).length">
    <p class="text-core-600 mr-1 w-full mb-1">
      Prochaine(s) Session(s)&nbsp;:
    </p>
    <ul class="pl-4">
      <li v-for="(session,index) in listSessions(sessions)"
          v-bind:key="`session_${index}`">

        <template v-if="index < sessions_show_max">
          <span class="mr-2 font-bold"
                v-bind:class="{'text-danger-500': isActiveFilteredCity(session.name.id)}">
            {{session.name.nom}}&nbsp;:
          </span>

          <ul class="list-disc pl-6">
            <li v-for="date in session.dates"
                v-bind:key="`${session.id}_${date.id}`"
                class=" mb-1 text-primary-800 font-bold">{{date.label}}</li>
          </ul>
        </template>

        <template v-if="sessions_show_max <= listSessions(sessions).length && index === sessions_show_max && !show_more">
          <div class="py-2 underline normal-case text-primary-800 hover:text-primary-900"
               v-bind:class="{'text-danger-500': isActiveFilteredCity(session.name.id)}"
               v-on:click.prevent="toggleCurrentList()">
            Voir plus de dates
          </div>
        </template>

        <template v-if="index >= sessions_show_max && show_more">
          <span class="mr-2 font-bold"
                v-bind:class="{'text-danger-500': isActiveFilteredCity(session.name.id)}">
            {{session.name.nom}}&nbsp;:
          </span>

          <ul class="list-disc pl-6">
            <li v-for="date in session.dates"
                v-bind:key="`${session.id}_${date.id}`"
                class=" mb-1 text-primary-800 font-bold">{{date.label}}</li>
          </ul>
        </template>
      </li>

      <li v-if="show_more">
        <div class="py-2 underline normal-case text-primary-800 hover:text-primary-900" v-on:click.prevent="toggleCurrentList()">
          Voir moins de dates
        </div>
      </li>

    </ul>
  </div>
</template>

<script>
import session_dates_functions from '@/mixin/session_dates_functions';

export default {
    mixins: [session_dates_functions],
    props: {
        sessions: Array
    },
    data() {
        return {
            show_more: false,
            sessions_show_max: 2
        };
    },
    computed: {

    },
    methods: {
        isActiveFilteredCity(city_id) {
            const query_cities = this.$route.query && this.$route.query.sessionLieuIds;

            const isQueried = query_cities && query_cities.split(',').some((id) => id == city_id);

            return isQueried;
        },
        toggleCurrentList() {
            this.show_more = !this.show_more;
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
