<template>
  <div class="w-full flex flex-wrap">
    <form-label v-bind:label="label"
                v-bind:icon="label_icon"
                class="flex-grow inline-flex font-bold mb-2 leading-none"/>

    <div v-if="advanced_scopes"
         class="btn-advanced-scopes"
         v-on:click.prevent.stop="emit(custom_emit)">
      <span class="btn-advanced_scopes__label">> recherche avancée</span>
    </div>

    <div class="input-block w-full relative flex items-center">
      <input v-model="input_value"
             class="inline-flex focus:outline-none w-full"
             v-bind:class="{'input-block--searchbox': searchbox}"
             v-bind:type="type ? type : 'text'"
             v-bind="$attrs"
             v-on:input="updateValue"
             v-bind:placeholder="placeholderComputed"
             v-on:keyup.enter="triggerClickSearchEvent()"/>

      <div v-if="searchbox && input_value && input_value.length > 0"
           v-on:click="triggerClearSearchEvent"
           v-tooltip="{
             content:'Effacer la recherche en cours',
             offset:10,
             placement:'bottom'
           }"
           class="cursor-pointer w-8 h-8 items-center justify-center rounded text-core-500 hover:text-core-600 absolute right-0 inline-flex mr-10">
        <font-awesome-icon icon="times" size="1x"/>
      </div>

      <div v-if="searchbox"
           v-on:click="triggerClickSearchEvent"
           v-tooltip="{
             content:'Lancer la recherche par mots clés',
             offset:10,
             placement:'bottom'
           }"
           class="cursor-pointer w-8 h-8 items-center justify-center bg-primary-900 hover:bg-primary-800 rounded text-white absolute right-0 inline-flex mr-2">
        <font-awesome-icon icon="search" size="1x"/>
      </div>

    </div>
  </div>
</template>

<script>
import formLabel from '@/components/utils/form-label.vue';

export default {
    components: {
        formLabel
    },
    inheritAttrs: false,
    props: {
        value: {
            type: String
        },
        name: {
            type: String
        },
        type: {
            type: String
        },
        label: {
            type: String,
            default: 'Input'
        },
        label_icon: {
            type: [String, Array]
        },
        placeholder: {
            type: String
        },
        required: {
            type: Boolean,
            default: false
        },
        searchbox: {
            type: Boolean,
            default: false
        },
        advanced_scopes: {
            type: Boolean,
            default: false
        },
        custom_emit: {
            type: String
        }
    },
    data() {
        return {
            input_value: this.value
        };
    },
    computed: {
        placeholderComputed() {
            return this.placeholder || `Saisir ${this.label.toLowerCase()}`;
        }
    },
    watch: {
        value(newVal, oldVal) {
            if (!newVal) {
                this.input_value = '';
            } else {
                this.input_value = newVal;
            }
        }
    },
    methods: {
        updateValue(e) {
            this.$emit('emit-name', this.name);
            this.$emit('input', this.input_value);
        },
        triggerClickSearchEvent(e) {
            this.$emit('click-search');
        },
        triggerClearSearchEvent(e) {
            this.$emit('clear-search');
        },
        emit(value) {
            this.$emit(value);
        }
    }
};
</script>

<style lang="scss" scoped>
.btn-advanced-scopes {
    @apply flex-shrink inline-flex justify-end items-center mb-2;
    .btn-advanced_scopes__label {
        @apply inline-flex cursor-pointer rounded z-10 w-full px-2 h-4 items-center justify-center font-bold text-white text-xs bg-primary-900 hover:bg-primary-800 shadow;
    }
}
.input-block {
  @apply appearance-none flex w-full bg-white text-core-700 border border-core-700 rounded py-3 px-4;

  .input-block--searchbox {
      @apply w-full truncate;
      max-width: calc(100% - 4rem);
  }

  &:focus {
    @apply outline-none border-core-500;
  }
}
</style>
