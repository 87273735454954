<template>
  <div class="flex flex-col mx-auto rounded overflow-hidden px-8 py-4"
       v-bind:class="`${width}`">

    <div class="flex flex-col w-full h-full items-center justify-center">
      <img class="flex w-full max-w-md h-auto"
           src="@/assets/img/404-ter.png"
           alt="Image Erreur 404"/>

      <div class="flex font-bold text-4xl">Erreur 404</div>
      <div class="flex text-core-700 text-lg">
        La ressource que vous essayez d'atteindre n'existe pas...
      </div>
      <router-link v-if="!no_return_home"
                   class="flex w-72 items-center rounded-sm bg-primary-900 rounded shadow text-core-100 py-2 px-4 mt-12 mx-auto"
                   v-bind:to="{name: 'catalogue'}">
        <font-awesome-icon icon="home" class="mr-3"/>
        <span class="inline-flex align-middle">Retourner à la page d'accueil</span>
      </router-link>
    </div>

  </div>
</template>

<script>
export default {
    props: {
        no_return_home: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: 'w-full'
        }
    }
};
</script>

<style lang="sass" scoped>
</style>
