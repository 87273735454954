<template>
  <div class="catalogue">

    <div ref="catalogue__header" class="catalogue__header">
      <catalogue-search-form v-bind:villes="villes_liste" v-bind:famille_produits="fetchFamiliesTypes"/>
    </div>

    <div class="catalogue__body">

      <div class="body__aside">
        <div ref="thematiques_wrapper"
             class="thematiques-wrapper"
             v-bind:style="cssVars">

          <catalogue-thematiques v-bind:categories="categories"
                                 v-bind:loading="loading"/>
        </div>
        <simplebar-scroll target="thematiques_wrapper"/>
      </div>

      <div class="body__main">
        <div ref="list_wrapper"
             v-bind:style="cssVars"
             class="list-wrapper">

          <catalogue-list v-bind:fiches="fiches"
                          v-bind:loading="loading"/>

        </div>
        <simplebar-scroll target="list_wrapper"/>
      </div>

    </div>

  </div>
</template>

<script>
import simplebarScroll from '@/components/utils/simplebar-scroll.vue';

import catalogueSearchForm from '@/components/utils/catalogue-search-form.vue';
import catalogueThematiques from '@/components/utils/catalogue-thematiques.vue';
import catalogueList from '@/components/utils/catalogue-list.vue';

import {API} from '@/http-common';
import api_utils from '@/mixin/api_utils';

import {ACTION_TYPES} from '@/store/constants/ActionTypes';

import {createNamespacedHelpers} from 'vuex';
const {mapState: mapStateObserver} = createNamespacedHelpers('observer');
const {
    mapState: mapStateCatalogue,
    mapActions: mapActionsCatalogue
} = createNamespacedHelpers('catalogue');

export default {
    components: {
        simplebarScroll,
        catalogueList,
        catalogueSearchForm,
        catalogueThematiques
    },
    mixins: [api_utils],
    props: ['query'],
    data() {
        return {
            loading: false,
            fiches: null,
            categories: [],
            villes_liste: [],
            params: {},
            catalogue_header_height: null
        };
    },
    computed: {
        ...mapStateObserver({
            offset: (state) => state.offset,
        }),
        ...mapStateCatalogue({
            filters: 'filters'
        }),
        cssVars() {
            return {
                '--navbar-height': this.offset + 'px',
                '--header-height': this.catalogue_header_height + 'px'
            };
        },
        fetchFamiliesTypes() {
            return this.categories.filter(category => category.name === 'famille_produits');
        },
    },
    watch: {
        ['$route.query']() {
            this.fetchSearch();
        },
        filters() {
            const query = this.formatParams(this.filters);
            if (JSON.stringify(query) !== JSON.stringify(this.query)) {
                this.$router.push({path: '/', query});
            }
        }
    },
    created() {
        this.initFilters();
        this.fetchSearch();
        this.fetchAvailableVilles();
    },
    mounted() {
        this.catalogue_header_height = this.$refs.catalogue__header.clientHeight;
    },
    methods: {
        ...mapActionsCatalogue([ACTION_TYPES.INIT_FILTERS_FROM_QUERY, ACTION_TYPES.SET_FILTER]),
        initFilters() {
            this.initFiltersFromQuery(this.query);
        },
        async fetchSearch() {
            this.loading = true;

            const request = 'catalogue/recherche';
            await API.get(request, {params: this.query})
                .then((response) => {
                    this.fiches = response.data.catalogues;
                    this.categories = response.data.categories;

                    this.loading = false;
                });
        },
        async fetchAvailableVilles() {
            const request = 'catalogue/villes';
            await API.get(request)
                .then((response)=> {
                    this.villes_liste = response.data.map((ville) => {
                        return {
                            id: ville.id,
                            label: ville.nom
                        };
                    });
                });
        }
    },
};
</script>

<style lang="scss" scoped>
/deep/ .simplebar-content {
  @apply h-full;
}
.catalogue {
    @apply flex flex-col overflow-y-auto overflow-x-hidden;
    .catalogue__header {
        // @apply z-10;
    }

    .catalogue__body {
        @apply w-full flex;

        // Styles partagés pour simplebar
        .list-wrapper,
        .thematiques-wrapper {
            /deep/ .simplebar-scrollbar::before {
                opacity: 0.1;
                @apply bg-core-600 transition ease-out duration-300;
            }
            &:hover /deep/ .simplebar-scrollbar::before {
                @apply opacity-25;
            }
        }

        .body__aside {
            @apply w-1/4 relative;

            .thematiques-wrapper {
                &:before {
                    content: '';
                    @apply absolute h-2 inset-0 z-10;
                    background: linear-gradient(#f7f7f7, transparent);
                }
                @apply overflow-y-auto overflow-x-hidden;
                height: calc(100vh - var(--navbar-height) - var(--header-height));
            }
        }

        .body__main {
            @apply w-3/4 relative;

            .list-wrapper {
                &:before {
                    content: '';
                    @apply absolute h-2 inset-0 z-10;
                    background: linear-gradient(#f7f7f7, transparent);
                }
                @apply overflow-y-auto overflow-x-hidden;
                height: calc(100vh - var(--navbar-height) - var(--header-height));
            }
        }
    }
}
</style>
