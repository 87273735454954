const FILTERS = {
    thematiquesIds: {
        name: 'thematiquesIds'
        , isArray: true
    },
    typesIds: {
        name: 'typesIds'
        , isArray: true
    },
    metiersIds: {
        name: 'metiersIds'
        , isArray: true
    },
    sessionLieuIds: {
        name: 'sessionLieuIds'
        , isArray: true
    },
    dateDebut: {
        name: 'dateDebut'
        , isArray: false
    },
    dateFin: {
        name: 'dateFin'
        , isArray: false
    },
    motClesQuery: {
        name: 'motClesQuery'
        , isArray: false
    },
    certifiant: {
        name: 'certifiant'
        , isArray: false
    },
    publicConcernesIds: {
        name: 'publicConcernesIds'
        , isArray: true
    },
    tagsIds: {
        name: 'tagsIds'
        , isArray: true
    },
    collectionsIds: {
        name: 'collectionsIds'
        , isArray: true
    },
};

const map_categories_filters = {
    metiers: 'metiersIds',
    thematiques: 'thematiquesIds',
    types_formations: 'typesIds'
};

const map_tag_colors = {
    metier: 'bg-accent-600 text-white',
    type_formation: 'bg-accent-500 text-white',
    thematique: 'bg-accent-700 text-white',
    collection: 'bg-core-700 text-white',
    tag: 'bg-core-500 text-white',
};

const map_type_colors = {
    1: 'teal-600',
    2: 'blue-600',
    3: 'cyan-600',
    4: 'orange-600',
    5: 'purple-600',
    6: 'green-600',
    7: 'danger-600',
};
// {"1": "Présentiel"},
// {"2": "e-learning"},
// {"3": "Webinaire"},
// {"4": "Parcours"},
// {"5": "Classe virtuelle"},
// {"6": "Conférence"},
// {"7": "Atelier"}

const map_metiers = {
    CAC: {
        label: 'commissaire aux comptes',
        icon: ['fas', 'thumbs-up']
    },
    EC: {
        label: 'expert comptable',
        icon: ['far', 'thumbs-up']
    }
};

const map_months = [
    {id: 1, label: 'Janvier'},
    {id: 2, label: 'Février'},
    {id: 3, label: 'Mars'},
    {id: 4, label: 'Avril'},
    {id: 5, label: 'Mai'},
    {id: 6, label: 'Juin'},
    {id: 7, label: 'Juillet'},
    {id: 8, label: 'Aout'},
    {id: 9, label: 'Septembre'},
    {id: 10, label: 'Octobre'},
    {id: 11, label: 'Novembre'},
    {id: 12, label: 'Décembre'}
];

export {
    map_tag_colors,
    map_metiers,
    map_months,
    map_categories_filters,
    map_type_colors,
    FILTERS
};
