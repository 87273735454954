<template>
  <div class="breadcrumbs">

    <template v-for="(breadcrumb, index) in breadcrumbs_list">

      <router-link tag="a"
                   class="breadcrumbs__link"
                   v-bind:key="`breadcrumb-${index}`"
                   v-bind:to="{ name: breadcrumb }">
        <span v-if="index === 0" class="mr-2">
          <font-awesome-icon icon="square"/>
        </span>

        <span class="breadcrumbs__label">{{breadcrumb}}</span>

        <span v-if="breadcrumbs_list.length > index + 1" class="mx-1">
          <font-awesome-icon icon="chevron-right"/>
        </span>
      </router-link>

    </template>

  </div>
</template>

<script>
export default {
    computed: {
        breadcrumbs_list() {
            const path = this.$route.fullPath;
            const splitted_path = path.split('/');
            return splitted_path.filter(pathItem => pathItem.length);
        }
    }
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
    @apply font-bold text-xs text-core-700 uppercase;

    .breadcrumbs__link {
        @apply font-bold text-xs text-core-700 uppercase outline-none;
    }

    .breadcrumbs__label {
        @apply align-middle;

        &:hover {
            @apply underline text-core-700;
        }

    }

}
</style>
