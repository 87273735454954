<template>
  <h1 class="page-title">{{current_path_name}}</h1>
</template>

<script>
export default {
    computed: {
        current_path_name() {
            return this.$route.meta.label ? this.$route.meta.label : this.$route.name;
        }
    }
};
</script>

<style lang="scss" scoped>
.page-title {
    @apply font-bold text-5xl uppercase;
}
</style>
