<template>
  <div class="mx-auto">
    <current-page-title no_breadcrumb no_divider/>
    {{current_user_info}}

    <spinner v-if="!current_user_info"/>

    <div v-else class="w-full flex my-16 mb-40">

      <div class="flex-1 rounded-lg border border-core-400 bg-white py-6 px-10 shadow-lg flex flex-col justify-between leading-normal">

        <div class="mb-4">
          <div class="text-core-700 font-bold text-xl my-2 uppercase">
            {{current_user_info}}
          </div>
        </div>

      </div>
    </div>

  </div>
</template>
<script>
import {createNamespacedHelpers} from 'vuex';
const {mapState: mapStateAuth} = createNamespacedHelpers('auth');

import spinner from '@/components/utils/spinner.vue';
import currentPageTitle from '@/components/main/main-page-title.vue';

export default {
    components: {
        spinner,
        currentPageTitle
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        ...mapStateAuth({
            user: (state) => state.user
        }),
        current_user_info() {
            return this.user;
        }
    },
};
</script>

<style lang="scss" scoped>

</style>
