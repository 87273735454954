<template>
  <div class="main-page-title">
    <breadcrumb-nav v-if="!no_breadcrumb"/>
    <page-title v-if="!no_title"/>
    <hr v-if="!no_divider" class="text-core-300 mt-6 mb-4 max-w-6xl mx-auto"/>

  </div>
</template>

<script>
import breadcrumbNav from '@/components/utils/breadcrumb-nav.vue';
import pageTitle from '@/components/utils/page-title.vue';

export default {
    components: {
        breadcrumbNav,
        pageTitle
    },
    props: {
        no_breadcrumb: Boolean,
        no_title: Boolean,
        no_divider: Boolean
    }
};
</script>

<style lang="scss" scoped>
.main-page-title {
    .main-page-title__breadcrumb {
        @apply font-bold text-xs text-core-700 uppercase;
    }

    .main-page-title__link {
        @apply outline-none;

        .main-page-title-label {
            &:hover {
                @apply underline text-core-700;
            }
        }
        @apply font-bold text-xs text-core-700 uppercase;
    }

    .main-page-title__title {
        @apply font-bold text-3xl uppercase;
    }
}
</style>
