import fr from 'date-fns/locale/fr';
import {eachDayOfInterval, compareDesc, format, parse} from 'date-fns';

export default {
    methods: {
        parseToFrDate(date) {
            return parse(date, 'dd/MM/yyyy', new Date());
        },
        mapEachDaysOfSession(session) {
            // console.log(session.sessionLieu.nom, session.etapes.length && session.etapes);

            const debut = `${session.dateDebut}`;
            const fin = `${session.dateFin}`;

            const date_start = this.parseToFrDate(debut);
            const date_end = this.parseToFrDate(fin);

            const start = compareDesc(date_start, date_end) == 1 ? date_start : date_end;
            const end = compareDesc(date_start, date_end) == 1 ? date_end : date_start;

            const list_of_days = eachDayOfInterval(
                {start, end}
            );

            const dateDisplayMode = (list, day, index) => {
                if (list.length - 1 !== index && day.getMonth() == list[list.length - 1].getMonth()) {
                    return 'd';
                } else if (day.getMonth() !== list[list.length - 1].getMonth()) {
                    return 'd MMMM';
                } else {
                    return 'd MMMM yyyy';
                }
            };

            const formated_list = list_of_days
                .map((day, index) => format(day, dateDisplayMode(list_of_days, day, index), {locale: fr}));

            return formated_list;
        },
        rangeOfDays(session) {
            const array_each_days = this.mapEachDaysOfSession(session);

            const days_string = array_each_days.length > 1 ? `Du ${array_each_days[0]} au  ${array_each_days[array_each_days.length - 1]}` : `Le ${array_each_days[array_each_days.length - 1]}`;

            return days_string;
        },
        listSessions(list) {
            // declaring helpers functions
            const sortByName = (a, b) => {
                const nameA = a.name.nom.toUpperCase();
                const nameB = b.name.nom.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            };

            const sortByDate = (a, b) => {
                return compareDesc(
                    this.parseToFrDate(b.dateDebut),
                    this.parseToFrDate(a.dateDebut)
                );
            };

            const getNestedObject = (nestedObj, pathArr) => {
                return pathArr.reduce((obj, key) =>
                    obj && obj[key] !== 'undefined' ? obj[key] : undefined, nestedObj);
            };

            // starting to filter and sort
            const sessionsSet = [];

            // get list of unique locations
            const uniqueLocations = list.reduce((acc, obj) => {
                if (obj.sessionLieu && obj.sessionLieu.id) {
                    if (acc.indexOf(obj.sessionLieu.id) === -1) {
                        acc.push(obj.sessionLieu.id);
                    }
                } else {
                    if (acc.indexOf(obj.type.id) === -1) {
                        acc.push(obj.type.id);
                    }
                }
                return acc;
            }, []);

            // assign list of sessions for each unique locations
            uniqueLocations.forEach((id) => {
                const filtered_sessions = list.filter(({sessionLieu, type}) => {
                    return sessionLieu ? sessionLieu.id === id : type.id === id;
                });

                // sorting up sessions by dates
                filtered_sessions.sort(sortByDate);

                // transmorming into strings that describe dates range
                const dates = filtered_sessions.map((session) => {
                    return {id: session.sessionId, label: this.rangeOfDays(session)};
                });

                // catch the location name wether its a city or a type
                const name = Object.prototype.hasOwnProperty.call(filtered_sessions[0], 'sessionLieu') ? {
                    id: getNestedObject(filtered_sessions[0], ['sessionLieu', 'id']),
                    nom: getNestedObject(filtered_sessions[0], ['sessionLieu', 'nom'])
                } : {
                    id: getNestedObject(filtered_sessions[0], ['type', 'id']),
                    nom: getNestedObject(filtered_sessions[0], ['type', 'label'])
                };

                // fill array of unique locations and their list of sessions
                sessionsSet.push({
                    name,
                    dates,
                    sessions: filtered_sessions
                });
            });

            // lastly returning array sorted by unique locations names
            return sessionsSet.sort(sortByName);
        },
    }
};
