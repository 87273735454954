import { render, staticRenderFns } from "./logout-button.vue?vue&type=template&id=53fd5f2e&scoped=true&"
import script from "./logout-button.vue?vue&type=script&lang=js&"
export * from "./logout-button.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53fd5f2e",
  null
  
)

export default component.exports