<template>
  <div class="display-fiches-catalogue">
    <template v-for="(fiche,i) in fiches">

      <router-link class="display-fiches-catalogue__link"
                   v-bind:to="{ name: 'fiche', params: {fiche_id: fiche.reference}}"
                   v-bind:key="`link-${i}-${fiche.reference}`">

        <div class="link__content">
          <div class="link__content--anchor"
               v-bind:class="type_anchor_color(fiche.type.id)"></div>

          <div class="content__headline">
            <div class="headline__decoration--triangle">
              <div class="triangle__shape"></div>
            </div>
            <label-list v-bind:list="fiche.collections"
                        hide_label
                        class="content__item pb-3"/>
            <div class="headline__title">
              {{fiche.titre}}
            </div>
            <h2 v-if="fiche.sousTitre"
                class="headline__subtitle">
              {{fiche.sousTitre}}
            </h2>
            <richtext-block v-if="fiche.preambule"
                            class="headline__description"
                            v-bind:source="fiche.preambule"
                            v-bind:read_more_limit="300"
                            left_indent
                            preview/>
            <div v-if="fiche.certifiant" class="ribbon-tiny ribbon-top-right">
              <div class="ribbon-tiny__inner">
                <font-awesome-icon icon="graduation-cap" size="1x"/>
                <span class="pl-0.5">Certifiant</span>
              </div>
            </div>
          </div>

          <div class="content__body">
            <div class="absolute bg-core-700 top-0 right-0 mt-2 mr-2 text-xs rounded text-core-100 lowercase font-bold px-3 py-0.5 shadow"
                 v-bind:class="type_bg_color(fiche.type.id)">
              {{fiche.type.label}}
            </div>

            <div class="content__body__info">
              <p v-if="fiche.produitReference" class="info">
                <span class="info__label">
                  Code&nbsp;:
                </span>
                <span class="info__value">
                  {{fiche.produitReference}}
                </span>
              </p>

              <p v-if="fiche.dureeMinutes" class="info">
                <span class="info__label">
                  Durée&nbsp;:
                </span>
                <span class="info__value">
                  {{fiche.dureeMinutes | minutesIntoHours}}
                </span>
                <span v-if="fiche.dureeJours"
                      class="info__value pl-1">
                  ({{fiche.dureeJours | displayJourString}})
                </span>
              </p>

              <p v-if="fiche.prixCatalogue" class="info">
                <span class="info__label">
                  Tarif&nbsp;:
                </span>
                <span class="info__value">
                  {{fiche.prixCatalogue}}&nbsp;€
                </span>
              </p>

              <p v-if="fiche.sessions.length" class="info mt-4">
                <catalogue-session-list v-bind:sessions="fiche.sessions"/>
              </p>
            </div>
          </div>

        </div>

      </router-link>

    </template>
  </div>
</template>

<script>
import helpers_filters from '@/mixin/helpers_filters';
import session_dates_functions from '@/mixin/session_dates_functions';

import richtextBlock from '@/components/utils/richtext-block.vue';
import catalogueSessionList from '@/components/utils/catalogue-session-list.vue';

import {
    map_tag_colors, map_type_colors
} from '@/assets/data/data_maps.js';
import labelList from '@/components/utils/label-list';

export default {
    components: {
        labelList,
        richtextBlock,
        catalogueSessionList
    },
    mixins: [helpers_filters, session_dates_functions],
    props: {
        fiches: Array
    },
    data() {
        return {
            show_more: {},
            sessions_show_max: 3,
            map_type_colors
        };
    },
    methods: {
        type_anchor_color(id) {
            return `border-${map_type_colors[id]}`;
        },
        type_bg_color(id) {
            return `bg-${map_type_colors[id]}`;
        },
        toggleCurrentList(session_id, bool) {
            this.$set(this.show_more, session_id, bool);
        },
        activeCategoryClasses(name, id) {
            const name_singular = name.slice(0, -1);
            const current = `current_${name_singular}`;

            const colors_map = {...map_tag_colors};

            return this[current].includes(id) ? colors_map[name_singular] : 'bg-white text-error-600 ';
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/utils/mixins.scss';

.display-fiches-catalogue {
    @apply flex flex-col h-full justify-center mt-8 mb-4;

    .display-fiches-catalogue__link {
        @apply flex bg-white rounded-lg overflow-hidden shadow mb-3 transition ease-out duration-200;

        &:hover {
            @apply shadow-md;
            .headline__title {
                @apply text-primary-800;
            }
            // Inflating box's transition effect
            transform: scale(1.02);
            .available-img {
                transform: scale(1.1);
            }
        }
    }

    .link__content {
        @apply relative w-full flex items-center leading-tight;

        .link__content--anchor {
            @apply absolute left-0 top-0 border-t-8 border-l-8 w-12 h-10 z-10;
        }

        .content__headline {
            @apply relative w-4/5 h-full tracking-tight pl-8 pt-6 pr-16 pb-6 shadow bg-white;

            .headline__decoration--triangle {
              @apply absolute w-12 h-12 right-0 bg-transparent overflow-hidden;
              top:50%;
              margin: -1.5rem -3rem auto auto;

              .triangle__shape {
                margin-top: 3px;
                @apply w-10 h-10 bg-white shadow;
                box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.09);
                margin-left: -1.5rem;
                transform: rotate(45deg);
              }
            }

            .headline__title {
                @apply font-bold text-xl mb-2;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                @supports (-webkit-line-clamp: 2) {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: initial;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                }
            }

            .headline__subtitle {
                @apply w-3/4 flex text-core-500 text-base font-bold mb-4;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                @supports (-webkit-line-clamp: 3) {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: initial;
                  display: -webkit-box;
                  -webkit-line-clamp: 3;
                  -webkit-box-orient: vertical;
                }
            }

            .headline__description {
                @apply text-sm;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                @supports (-webkit-line-clamp: 5) {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: initial;
                  display: -webkit-box;
                  -webkit-line-clamp: 5;
                  -webkit-box-orient: vertical;
                }
            }
        }

        .content__body {
            @apply w-2/5 flex items-center h-full bg-accent-100 pl-12 pt-8 pb-6;

            .content__body__info {
                @apply w-full;
                .info {
                    @apply flex flex-wrap px-3 uppercase text-sm;
                    .info__label {
                        @apply text-core-600 mr-1;
                    }
                    .info__value {
                      @apply text-primary-800 font-bold;
                    }
                }
            }

            .content__body__tags {
                @apply w-2/3;
                .tags__category {
                    @apply my-3;
                    .tags__category__inner {
                        /deep/ .tag {
                          padding: 1px;
                          @apply flex-auto text-center whitespace-normal leading-tight bg-transparent shadow-none text-accent-700 px-4;
                        }
                    }
                }
            }

        }
    }
}
</style>
