import Vue from 'vue';

import App from './App.vue';
import router from './router';
import store from './store';

import './vue-tooltip';
import './vue-toasted';
import './vue-fontawesome';
import './vue-validate';
import i18n from './vue-i18n';

import './assets/css/tailwind.scss';

Vue.config.productionTip = false;

// ROUTER BEFORE EACH -> BEFORE VM INSTANCE
router.beforeEach(async(to, from, next) => {
    // Check the authentication status (OIDC Client)
    if (!store.state.auth.authenticated) {
        await store.dispatch('auth/applyAuthenticationStatus');
    }

    const isAuthenticated = store.state.auth.authenticated;

    const isLoginPage = from.matched.some((record) => record.name === 'connexion');
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    // console.log(requiresAuth, isLoginPage, isAuthenticated);

    // console.log('REDIRECT STATE', store.state.auth.redirect_route_path);
    if (requiresAuth && !isAuthenticated) {
        if (!isLoginPage) {
            store.commit('auth/setRedirectUrlPath', to.fullPath);
            next({
                name: 'connexion',
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

//  VM INSTANCE
const vm = new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App)
});

vm.$mount('#app');
