<template>
  <article>
    <section class="text-center">
      <current-page-title no_breadcrumb no_divider/>

      <div class="w-full mx-auto mt-16 mb-32">
        <template v-if="message">
          <div class="text-xl font-bold ">
            {{message}}
          </div>
          <router-link v-bind:to="{name: 'accueil'}" class="bg-white py-1 px-5 shadow rounded-lg my-3 hover:shadow-sm border text-core-600 hover:text-core-800 transition duration-100 ease-in inline-flex items-center">
            <font-awesome-icon class="align-middle" icon="home"/>
            <span class="align-middle ml-3">Retourner à l'accueil</span>
          </router-link>
          <hr class="text-core-300 my-4"/>
        </template>
        <login-form v-if="!authenticated"/>
        <spinner v-else/>
      </div>
    </section>
  </article>
</template>

<script>
import currentPageTitle from '@/components/main/main-page-title.vue';
import loginForm from '@/components/utils/login-form.vue';
import spinner from '@/components/utils/spinner.vue';

import {createNamespacedHelpers} from 'vuex';
const {mapState: mapStateAuth} = createNamespacedHelpers('auth');

export default {
    components: {
        currentPageTitle,
        loginForm,
        spinner
    },
    props: ['message'],
    computed: {
        ...mapStateAuth({
            authenticated: (state) => state.authenticated,
        })
    }
};
</script>

<style lang="scss" scoped>
</style>
