import { render, staticRenderFns } from "./catalogue-session-list.vue?vue&type=template&id=4b2d6c4c&scoped=true&"
import script from "./catalogue-session-list.vue?vue&type=script&lang=js&"
export * from "./catalogue-session-list.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b2d6c4c",
  null
  
)

export default component.exports