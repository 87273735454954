import Catalogue from '@/views/_catalogue/index.vue';
import ErrorNotFound from '@/views/errors/error-404.vue';
import AuthViewRedirector from '@/views/redirector.vue';
import AuthViewConnexion from '@/views/connexion.vue';
import AuthViewUtilisateur from '@/views/utilisateur.vue';

const navigation_tree = [
    {
        path: '/',
        name: 'catalogue',
        meta: {
            no_container_wrapper: true,
            show_header: true
        },
        props: route => ({query: route.query}),
        component: Catalogue
    },
    {
        path: '/fiche/:fiche_id',
        name: 'fiche',
        props: true,
        component: () => import('@/views/_catalogue/fiche.vue'),
        meta: {
            no_container_wrapper: true,
            show_header: true
        },
    },
    {
        path: '/fiche/preview/:preview_id',
        name: 'preview',
        props: true,
        component: () => import('@/views/_catalogue/fiche.vue'),
        meta: {
            no_container_wrapper: true
        },
    },
    {
        path: '/connexion',
        name: 'connexion',
        props: true,
        meta: {
            label: 'Connexion',
            show_header: true,
        },
        component: AuthViewConnexion
    },
    {
        path: '/utilisateur',
        name: 'utilisateur',
        meta: {
            show_header: true,
            requiresAuth: true,
            label: 'Informations utilisateur'
        },
        component: AuthViewUtilisateur,
    },
    {
        path: '*',
        name: 'error-404',
        meta: {
            show_header: true,
        },
        component: ErrorNotFound,
    },
    {
        path: '/redirector',
        name: 'redirector',
        meta: {
            show_header: true,
            no_container_wrapper: true
        },
        component: AuthViewRedirector,
    }
];

export default {
    navigation_tree
};
