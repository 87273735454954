<template>
  <router-link v-if="href"
               v-bind:to="href"
               class="text-link">

    <div class="text-link__icon"
         v-bind:class="{'bg-white text-core-700': dark}"
         v-bind:style="`width: ${width}; height: ${height}`">
      <font-awesome-icon v-bind:icon="icon" v-bind:size="size"/>
    </div>

    <span v-if="label"
          class="text-link__name"
          v-bind:class="`mx-${label_margin}`">{{label}}</span>

  </router-link>

  <div v-else class="text-link">

    <div class="text-link__icon"
         v-bind:class="{'bg-white text-core-700': dark}"
         v-bind:style="`width: ${width}; height: ${height}`">
      <font-awesome-icon v-bind:icon="icon" v-bind:size="size"/>
    </div>

    <span v-if="label"
          class="text-link__name"
          v-bind:class="text_margin">{{label}}</span>

  </div>
</template>

<script>
export default {
    props: ['href', 'icon', 'size', 'label', 'label_margin', 'width', 'height', 'dark'],
    computed: {
        text_margin() {
            return `ml-${this.label_margin}`;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/utils/mixins.scss';

.text-link {
    @apply w-full h-full;
    @include text-link();

    .text-link__icon {
        @include round-icon();
    }

    .text-link__name {
        @apply truncate;
    }
}
</style>
