<template>
  <span class="cursor-pointer">
    <a v-bind:href="logoutFromOidcProvider">
      <text-button v-bind:label="'Se déconnecter'"
                   size="lg"
                   icon="lock"
                   width="2rem"
                   height="2rem"
                   dark="false"
                   label_margin="5"
                   v-bind:class="classes"/>
    </a>
  </span>
</template>

<script>
import textButton from '@/components/utils/text-button.vue';

export default {
    components: {
        textButton
    },
    props : {
        classes: {
            type: String,
            default() {
                return 'hover:bg-primary-800 py-2 px-3';
            }
        }
    },
    computed: {
        logoutFromOidcProvider() {
            return `${process.env.VUE_APP_API_AUTH}/logout?to=https://${process.env.VUE_APP_HOST_CATALOGUE}`;
        },
    }
};
</script>

<style lang="scss" scoped>
</style>
